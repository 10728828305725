import { APP_BASE_HREF, isPlatformBrowser } from '@angular/common';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import {
  ErrorHandler,
  Inject,
  Injectable,
  Optional,
  PLATFORM_ID,
} from '@angular/core';
import { Keychain } from '@awesome-cordova-plugins/keychain/ngx';
import { Capacitor } from '@capacitor/core';
import { DeskproChat } from '@freelancer/deskpro-chat';
import { IpBlacklist } from '@freelancer/ip-blacklist';
import { Location } from '@freelancer/location';
import { UserAgent } from '@freelancer/user-agent';
import { isDefined, isEqual } from '@freelancer/utils';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CookieService } from 'ngx-cookie';
import type { Observable } from 'rxjs';
import { BehaviorSubject, firstValueFrom, of } from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  filter,
  finalize,
  map,
  skip,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import type { NavigationSwitchAccountResultAjax } from './auth.config';
import { AuthConfig, AUTH_CONFIG } from './auth.config';
import type {
  AuthServiceInterface,
  AuthState,
  DeviceTokenResultAjax,
  RawAuthResponseData,
  SwitchAccountResultAjax,
} from './interface';

@UntilDestroy({ className: 'Auth' })
@Injectable({
  providedIn: 'root',
})
export class Auth implements AuthServiceInterface {
  private _authStateSubject$ = new BehaviorSubject<AuthState | undefined>(
    undefined,
  );
  private beforeLogoutActions: (() => Promise<void>)[] = [];
  private isInitialized: boolean;
  /* Is the authState currently being updated? */
  private authStateLocked = false;

  private hasDeviceToken: boolean;
  private deviceToken: string | undefined;

  get authState$(): Observable<AuthState | undefined> {
    if (!this.isInitialized) {
      this.init();
    }
    return this._authStateSubject$.asObservable().pipe(
      filter(() => !this.authStateLocked),
      distinctUntilChanged(isEqual),
    );
  }

  private authCookiesExpires: Date = new Date(
    Capacitor.isNativePlatform()
      ? new Date().setFullYear(new Date().getFullYear() + 1)
      : new Date().setMonth(new Date().getMonth() + 1),
  );

  constructor(
    private cookies: CookieService,
    private http: HttpClient,
    private location: Location,
    private userAgent: UserAgent,
    private iosKeychain: Keychain,
    private errorHandler: ErrorHandler,
    private deskproChat: DeskproChat,
    private ipBlacklist: IpBlacklist,
    @Inject(AUTH_CONFIG) private authConfig: AuthConfig,
    @Inject(PLATFORM_ID) private platformId: Object,
    @Optional() @Inject(APP_BASE_HREF) private baseHref?: string,
  ) {}

  private init(): void {
    // read session from cookie if any
    let userId;
    let token;
    if (isPlatformBrowser(this.platformId)) {
      userId = this.cookies.get(this.authConfig.userIdCookie);
      token = this.cookies.get(this.authConfig.authHashCookie);
    }
    if (userId && token) {
      this._authStateSubject$.next({ userId, token });
    } else if (Capacitor.getPlatform() === 'ios') {
      // In case we don't have any auth token in the cookie,
      // attempts to retrieve it from the IOS keychain.
      this.iosKeychain
        .getJson(this.authConfig.authIosKeychainToken)
        .then(value => {
          if (isDefined(value)) {
            this._authStateSubject$.next({
              userId: value.userId,
              token: value.token,
            });
            this.storeAuthSession(
              value.userId,
              value.token,
              this.authCookiesExpires,
            );
          } else {
            this._authStateSubject$.next(undefined);
          }
        })
        .catch(err => {
          this._authStateSubject$.next(undefined);
          this.errorHandler.handleError(err);
        });
    } else {
      this._authStateSubject$.next(undefined);
    }
    // keep auth cookies in sync with session
    // eslint-disable-next-line local-rules/no-ignored-subscription
    this._authStateSubject$.pipe(skip(1)).subscribe(s => {
      if (s) {
        this.storeAuthSession(s.userId, s.token, this.authCookiesExpires);
      } else {
        this.cookies.remove(this.authConfig.userIdCookie);
        this.cookies.remove(this.authConfig.authHashCookie);

        // Remove the user ID and token from the ios keychain.
        if (Capacitor.getPlatform() === 'ios') {
          this.iosKeychain.remove(this.authConfig.authIosKeychainToken);
        }
      }
    });

    // Enables the `webapp_local` query param to be used to pass down the
    // current auth state to a localhost instance of the webapp for easier
    // debugging/session sharing.
    const url = new URL(this.location.href);
    if (url.searchParams.has('webapp_local') && url.hostname !== 'localhost') {
      // Fetch webapp_local port from url argument, default to 7766
      const port = url.searchParams.get('webapp_local')
        ? url.searchParams.get('webapp_local')
        : 7766;
      url.searchParams.delete('webapp_local');

      const baseUrl = this.baseHref ? this.baseHref.replace(/\/$/, '') : '';
      const absoluteUrl = url
        .toString()
        .replace(url.origin, '')
        .replace(baseUrl, '');

      if (userId && token) {
        this.location.redirect(
          `http://localhost:${port}${baseUrl}/internal/auth/callback?uid=${userId}&token=${encodeURIComponent(
            token,
          )}&next=${encodeURIComponent(absoluteUrl)}`,
        );
      } else {
        this.location.redirect(
          `http://localhost:${port}${baseUrl}${absoluteUrl}`,
        );
      }
    }

    this.isInitialized = true;
  }

  async getDeviceToken(): Promise<string | undefined> {
    if (!this.isInitialized) {
      this.init();
    }

    // Check it whether we have the deviceToken or we need to request it
    if (!this.hasDeviceToken) {
      this.deviceToken = await firstValueFrom(
        this.http
          .get<RawAuthResponseData<DeviceTokenResultAjax | undefined>>(
            `${this.authConfig.baseUrl}/device`,
          )
          .pipe(
            catchError(async error => {
              // Redirect if 403 Http error response, else throw back the error
              if (error instanceof HttpErrorResponse) {
                await firstValueFrom(
                  this.ipBlacklist
                    .checkCaptchaRedirect(error)
                    .pipe(untilDestroyed(this)),
                );
              }
              throw error;
            }),
            map(response => response.result?.token),
            untilDestroyed(this),
          ),
      );
      this.hasDeviceToken = true;
    }
    return this.deviceToken;
  }

  getUserId(): Observable<string> {
    if (!this.isInitialized) {
      this.init();
    }
    return this.authState$.pipe(
      filter(isDefined),
      map(auth => auth.userId),
    );
  }

  isLoggedIn(): Observable<boolean> {
    if (!this.isInitialized) {
      this.init();
    }
    return this.authState$.pipe(map(state => !!state));
  }

  getAuthorizationHeader(): Observable<HttpHeaders> {
    if (!this.isInitialized) {
      this.init();
    }
    return this.authState$.pipe(
      map(auth => {
        let headers = new HttpHeaders();
        if (!auth) {
          return headers;
        }
        headers = headers.set(
          this.authConfig.authHeaderName,
          `${auth.userId};${auth.token}`,
        );
        return headers;
      }),
    );
  }

  setSession(userId: string, token: string): void {
    if (!this.isInitialized) {
      this.init();
    }
    this._authStateSubject$.next({ userId, token });
  }

  deleteSession(): void {
    if (!this.isInitialized) {
      this.init();
    }
    this._authStateSubject$.next(undefined);
  }

  // FIXME: T38832 This needs 2 AJAX calls because GAF.
  switchUser(newUserId: string): Promise<string | undefined> {
    if (!this.isInitialized) {
      this.init();
    }
    if (!this.authConfig.switchAccountHackyEndpoint) {
      throw new Error('missing authConfig.switchAccountHackyEndpoint');
    }
    return firstValueFrom(
      this.authState$.pipe(
        switchMap(auth => {
          if (!auth) {
            throw new Error('no user to switch account from');
          }

          const body = new FormData();
          body.append('user', auth.userId);
          body.append('other_user', newUserId);
          body.append('token', auth.token);
          return this.http
            .post<RawAuthResponseData<SwitchAccountResultAjax>>(
              `${this.authConfig.baseUrl}/switch_account/`,
              body,
            )
            .pipe(
              catchError(async error => {
                // Redirect if 403 Http error response, else throw back the error
                if (error instanceof HttpErrorResponse) {
                  await firstValueFrom(
                    this.ipBlacklist
                      .checkCaptchaRedirect(error)
                      .pipe(untilDestroyed(this)),
                  );
                }
                throw error;
              }),
              map(response => response.result.token),
            );
        }),
        withLatestFrom(this.getAuthorizationHeader()),
        switchMap(async param => {
          await Promise.all(this.beforeLogoutActions.map(action => action()));
          return param;
        }),
        switchMap(([newUserToken, authHeader]) => {
          // We need to lock the authState here as the current auth state is
          // going to be invalid pending the state to be emitted
          this.authStateLocked = true;
          const body = new FormData();
          body.append('user', newUserId);
          body.append('token', newUserToken);
          body.append('useRelativeUrl', '1');
          return this.http
            .post<RawAuthResponseData<NavigationSwitchAccountResultAjax>>(
              this.authConfig.switchAccountHackyEndpoint as string,
              body,
              {
                headers: authHeader,
              },
            )
            .pipe(
              catchError(async error => {
                // Redirect if 403 Http error response, else throw back the error
                if (error instanceof HttpErrorResponse) {
                  await firstValueFrom(
                    this.ipBlacklist
                      .checkCaptchaRedirect(error)
                      .pipe(untilDestroyed(this)),
                  );
                }
                throw error;
              }),
              map(response => ({ response, newUserToken })),
              take(1),
            );
        }),
        tap(({ newUserToken }) => {
          this.authStateLocked = false;
          this._authStateSubject$.next({
            userId: newUserId,
            token: newUserToken,
          });
        }),
        map(({ response }) =>
          'gotoUrl' in response.result ? response.result.gotoUrl : undefined,
        ),
        untilDestroyed(this),
      ),
    );
  }

  logout(): Observable<undefined> {
    if (!this.isInitialized) {
      this.init();
    }

    this.deskproChat.clearSession();

    return this.authState$.pipe(
      take(1),
      switchMap(async auth => {
        if (!auth) {
          throw new Error('no user to logout');
        }

        await Promise.all(this.beforeLogoutActions.map(action => action()));

        // We need to lock the authState here as the /logout call is going to
        // invalidate the auth token & we don't want the authState to be used
        // before it is reset to `undefined`
        this.authStateLocked = true;
        const body = new FormData();
        body.append('user', auth.userId);
        body.append('token', auth.token);

        return firstValueFrom(
          this.http.post(`${this.authConfig.baseUrl}/logout/`, body).pipe(
            catchError(async error => {
              // Redirect if 403 Http error response, else throw back the error
              if (error instanceof HttpErrorResponse) {
                await firstValueFrom(
                  this.ipBlacklist
                    .checkCaptchaRedirect(error)
                    .pipe(untilDestroyed(this)),
                );
              }
              throw error;
            }),
            take(1),
            untilDestroyed(this),
          ),
        );
      }),
      map(() => undefined),
      finalize(() => {
        this.authStateLocked = false;
        this._authStateSubject$.next(undefined);
      }),
      catchError((err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          // A client-side or network error occurred.
          throw err.error;
        }

        // The backend returned an unsuccessful response code.
        console.error(err.message, err.error);
        return of(undefined);
      }),
    );
  }

  /**
   * Register an action to be ran before logging the user out, allowing
   * authenticated calls to be made before the auth state is reset.
   */
  registerBeforeLogoutAction(action: () => Promise<void>): void {
    if (!this.isInitialized) {
      this.init();
    }
    this.beforeLogoutActions.push(action);
  }

  getCSRFToken(): string {
    if (!this.isInitialized) {
      this.init();
    }
    if (!this.authConfig.csrfCookie) {
      throw new Error('missing authConfig.csrfCookie');
    }
    let token = this.cookies.get(this.authConfig.csrfCookie);
    if (!token) {
      token = this.generateCSRFToken(
        64,
        '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ',
      );
      this.cookies.put(this.authConfig.csrfCookie, token, {
        expires: this.authCookiesExpires,
      });
    }
    return token;
  }

  refreshSession(expiryHours: number): void {
    if (!this.isInitialized) {
      this.init();
    }
    firstValueFrom(this.authState$.pipe(untilDestroyed(this))).then(
      authState => {
        const date = new Date();
        date.setHours(date.getHours() + expiryHours);
        if (authState) {
          this.storeAuthSession(authState.userId, authState.token, date);
        }
      },
    );
  }

  private generateCSRFToken(length: number, charset: string): string {
    let result = '';
    for (let i = length; i > 0; --i) {
      result += charset[Math.floor(Math.random() * charset.length)];
    }
    return result;
  }

  /**
   * Returns if the browser can properly set a cookie with SameSite=none
   * The page must be secure and the browser cannot be incompatible:
   * https://www.chromium.org/updates/same-site/incompatible-clients
   */
  private canSetSameSiteNone(): boolean {
    // can't set SameSite=None on non-secure cookies
    if (this.location.protocol !== 'https:') {
      return false;
    }

    // for most incompatible browsers, we can just test functionality
    // if it rejects a cookie with SameSite=None, it's incompatible
    this.cookies.remove('testsamesitenone');
    this.cookies.put('testsamesitenone', '1', { sameSite: 'none' });
    if (this.cookies.get('testsamesitenone') !== '1') {
      return false;
    }
    this.cookies.remove('testsamesitenone');

    // for Safari on Mac OS 10.14 and any browser on iOS 12,
    // the cookie is set but the option is wrong
    // so we need some manual UA-parsing logic
    const ua = this.userAgent.getUserAgent();
    const browser = ua.getBrowser();
    const os = ua.getOS();
    if (
      (browser.name === 'Safari' &&
        os.name === 'Mac OS' &&
        os.version === '10.14') ||
      (os.name === 'iOS' && os.version === '12')
    ) {
      return false;
    }

    return true;
  }

  private storeAuthSession(
    userId: string,
    token: string,
    authCookiesExpires: Date,
  ): void {
    // FIXME: T267853 - as any is needed to explicitly not set the SameSite property,
    // otherwise it will just default to Lax as specified in cookieOptionsFactory.
    const sameSite = this.canSetSameSiteNone() ? 'none' : ('' as any);
    this.cookies.put(this.authConfig.userIdCookie, userId, {
      expires: authCookiesExpires,
      sameSite,
    });
    this.cookies.put(this.authConfig.authHashCookie, token, {
      expires: authCookiesExpires,
      sameSite,
    });

    // Store the user ID and auth token to keychain for IOS client.
    if (Capacitor.getPlatform() === 'ios') {
      this.iosKeychain
        .setJson(
          this.authConfig.authIosKeychainToken,
          {
            userId,
            token,
          },
          false, // useTouchID
        )
        .catch(err => {
          this.errorHandler.handleError(err);
        });
    }
  }
}
